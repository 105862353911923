body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto-regular.eot');
  src: url('./assets/fonts/roboto-regular.eot') format('embedded-opentype'),
    url('./assets/fonts/roboto-regular.woff') format('woff'),
    url('./assets/fonts/roboto-regular.ttf') format('truetype'),
    url('./assets/fonts/roboto-regular.svg#robotoregular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto-bold.eot');
  src: url('./assets/fonts/roboto-bold.eot') format('embedded-opentype'),
    url('./assets/fonts/roboto-bold.woff') format('woff'),
    url('./assets/fonts/roboto-bold.ttf') format('truetype'),
    url('./assets/fonts/roboto-bold.svg#robotobold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto-light.eot');
  src: url('./assets/fonts/roboto-light.eot') format('embedded-opentype'),
    url('./assets/fonts/roboto-light.woff') format('woff'),
    url('./assets/fonts/roboto-light.ttf') format('truetype'),
    url('./assets/fonts/roboto-light.svg#robotolight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/roboto-lightitalic.eot');
  src: url('./assets/fonts/roboto-lightitalic.eot') format('embedded-opentype'),
    url('./assets/fonts/roboto-lightitalic.woff') format('woff'),
    url('./assets/fonts/roboto-lightitalic.ttf') format('truetype'),
    url('./assets/fonts/roboto-lightitalic.svg#robotolight_italic') format('svg');
  font-weight: 300;
  font-style: italic;
}
