.ASPSignupFormSidebar-integratedContent-jss1 {
    padding-bottom: 32px;
}
.ASPSignupFormSidebar-integratedContent-jss1:last-child {
    padding-bottom: 0;
}

.ASPSignupFormSidebar-integratedContent-jss3 {
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.231;
    letter-spacing: 0.02857em;
    text-transform: none;
}
.ASPSignupFormSidebar-integratedContent-jss5 {
    line-height: 1.25;
}
.ASPSignupFormSidebar-integratedContent-jss6 {
    display: inline-block;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ASPSignupFormSidebar-integratedContent-jss7 {
    font-weight: 700;
}
.ASPSignupFormSidebar-integratedContent-jss8 {
    font-style: italic;
}
.ASPSignupFormSidebar-integratedContent-jss9 {
    text-decoration: underline;
}

.ASPSignupFormSidebar-integratedContent-jss11 {
    margin: 0;
    padding: 0;
}

.ASPSignupFormSidebar-integratedContent-jss12 {
    margin-bottom: 16px;
    list-style-type: none;
}
.ASPSignupFormSidebar-integratedContent-jss13 {
    margin-right: 16px;
}
.ASPSignupFormSidebar-integratedContent-jss14 {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: bold;
    margin-bottom: 8px;
}
.ASPSignupFormSidebar-integratedContent-jss15 {
    margin-left: 40px;
}

.ASPSignupFormSidebar-integratedContent-MuiTypography-root {
    margin: 0;
}

.ASPSignupFormSidebar-integratedContent-MuiTypography-body2 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.375rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.275;
    letter-spacing: -0.003em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-body1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-caption {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.333;
    letter-spacing: 0;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-button {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 1.429;
    letter-spacing: 0.02857em;
    text-transform: none;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-h1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 2.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -0.01em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-h2 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 2.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.143;
    letter-spacing: -0.01em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-h3 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 2rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.167;
    letter-spacing: -0.01em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-h4 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: -0.008em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-h5 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.5rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: -0.006em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-h6 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1.25rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.143;
    letter-spacing: -0.003em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-subtitle1 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.75;
    letter-spacing: 0.00938em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-subtitle2 {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.57;
    letter-spacing: 0.00714em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-overline {
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 700;
    line-height: 1.333;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-srOnly {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-alignLeft {
    text-align: left;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-alignCenter {
    text-align: center;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-alignRight {
    text-align: right;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-alignJustify {
    text-align: justify;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-noWrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-gutterBottom {
    margin-bottom: 0.35em;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-paragraph {
    margin-bottom: 16px;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-colorInherit {
    color: inherit;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-colorPrimary {
    color: #208DCA;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-colorSecondary {
    color: #40607F;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-colorTextPrimary {
    color: rgba(0, 0, 0, 0.87);
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-colorTextSecondary {
    color: rgba(0, 0, 0, 0.54);
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-colorError {
    color: #D13C3C;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-displayInline {
    display: inline;
}
.ASPSignupFormSidebar-integratedContent-MuiTypography-displayBlock {
    display: block;
}


.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -moz-user-select: none;
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-colorPrimary {
    color: #208DCA;
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-colorSecondary {
    color: #40607F;
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-colorAction {
    color: rgba(0, 0, 0, 0.54);
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-colorError {
    color: #D13C3C;
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-colorDisabled {
    color: rgba(0, 0, 0, 0.26);
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-fontSizeInherit {
    font-size: inherit;
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-fontSizeSmall {
    font-size: 1.25rem;
}
.ASPSignupFormSidebar-integratedContent-MuiSvgIcon-fontSizeLarge {
    font-size: 2.1875rem;
}

